import axios from "axios";
import {toast} from "react-toastify";

import {BASE_URL, AUTH_BASE_URL} from "../constants/variables";
import {userService} from "../services/user.service";

export const request = axios.create({
    baseURL: BASE_URL,
});
export const fileRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        "X-CSRFToken": getCookie("csrftoken"),
        "Content-Type": "multipart/form-data",
    },
});
export const authRequest = axios.create({
    baseURL: AUTH_BASE_URL,
    headers: {
        "X-CSRFToken": getCookie("csrftoken"),
        "Content-Type": "application/json",
    },
});

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const subscribe = (store) => {
    request.interceptors.request.use((config) => {
            const csrftoken = getCookie("csrftoken");
            const url = config.url;
            if (url.split("/")[0] === "micro") {
                config.headers.Authorization = process.env.REACT_APP_MICROSERVICE_AUTH_TOKEN;
            } else {
                config.headers.Authorization = ["Token", userService.userPrivacy()].join(" ");
            }
            if (csrftoken) {
                config.headers.common["X-CSRFToken"] = csrftoken;
            }
            return config;
        },
        (err) => {
            toast.error(err.response.data.message);
        }
    );

    request.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem("personnel");
                window.location.reload();
            }
            throw error;
        }
    );
};

export default request;
